/*
 * @file
 * global.styles
 *
 * Styles you add here will load for all device sizes, they are "global", as
 * opposed to "responsive" styles, which only load for a given breakpoint (media
 * query) that you set in theme settings. See the README for more details.
 *
 *
 * How to use this file:
 * --------------------
 *
 * There are many empty selectors and some basic styles to act as a guide.
 * Nothing is set in stone and you can change anything - even delete all of it
 * and start with your own clean slate.
 *
 * To FORCE PRINT all selectors you can search and replace a single { (opening
 * curly brace) and insert an empty comment. This is very useful in conjuction
 * with FireSass: https://addons.mozilla.org/en-US/firefox/addon/firesass-for-firebug/
 *
 * See global.base also which includes normalize.css and some additional
 * helper classes and base styles.
 */
/* =============================================================================
 *   Base
 * ========================================================================== */
/*
 * To preserve theme settings never apply font properties to the HTML element.
 * This is critically important if you are using the Responsive JavaScript
 * feature as this relies on being able to set a pseudo font family on the HTML
 * element. If you need to set default font properties of any kind use the BODY
 * element as these can be overridden using font theme settings.
 */
html {
  background: #fff; }

body {
  font-family: "Trebuchet MS", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 87.5%;
  background-image: url("/sites/www.imagehair.co.nz/themes/imagehair/images/backgroundnew.gif");
  background-repeat: repeat-x;
  margin: 0;
  text-align: left; }

/* =============================================================================
 *   HTML Elements
 * ========================================================================== */
pre,
code,
tt,
samp,
kbd,
var {
  font-family: Consolas, Monaco, "Courier New", Courier, monospace, sans-serif; }

/* =============================================================================
 *   Wrappers
 * ========================================================================== */
/*
 * Page wrapper, includes the .container class which sets the overall page or section width
 * Main wrapper in page.tpl.php, the .container class sets the width of the page, do not add width to it!
 */
#page {
  background-color: #fff;
  box-shadow: 0px 0px 10px #999;
  margin-bottom: 20px;
  border-radius: 5px 5px 5px 5px; }
#page .container {
  background-color: #FFFFFF;
  box-shadow: 4px 4px 10px;
  min-height: 722px;
  position: relative; }

.front #page-title {
  color: #fff;
  margin: -9px; }

/* Contact Block */
#block-block-6 {
  margin-bottom: 0; }
#block-block-6 p {
  margin-bottom: 0; }
#block-block-6 a {
  color: #000; }

/*
 * Wraps the sidebars the content column
 */
#block-views-slideshow-block,
#block-views-slideshow-block .block-inner {
  margin: 0; }

/*
 * Main content column wrapper
 */
/*
 * Wraps the main-content-header, the content region and feed-icons. Use this wrapper to style the entire main content column
 */
/*
 * Wraps the content region, avoid applying styles to this wrapper, its used mainly for position and invisible gutters and can be problematic to style
 */
/*
 * Postscript
 */
/*
 * Footer wrapper
 */
#footer {
  background-color: #000;
  margin: 0;
  padding: 0 40px;
  position: relative; }
#footer h4 {
  margin-bottom: 0;
  color: #fff; }
#footer h4 a {
  color: #fff; }
#footer p {
  color: #999; }
#footer p a {
  color: #999; }

/*
 * Post script custom blocks
 */
#block-block-3,
#block-block-4,
#block-block-5 {
  float: left;
  margin: 0;
  width: 33%; }
#block-block-3 .block-inner,
#block-block-4 .block-inner,
#block-block-5 .block-inner {
  padding: 10px; }

#block-block-3 p {
  margin-bottom: 5px; }

#block-block-4 .block-inner {
  padding-left: 0;
  margin-left: 0; }

/* Footer */
#block-block-2 {
  clear: both;
  height: 25px;
  margin: 0;
  padding: 20px 20px 20px 0; }
#block-block-2 .block-inner {
  margin: 0; }

#block-block-2 a,
#block-block-2 p {
  color: #666; }
#block-block-2 a a,
#block-block-2 p a {
  color: #666; }

/*
 * Panels may need additional margin wrangling when in the $content region
 */
/*
 * Full Width Wrappers
 * These are used in the optional page--full-width-wrappers.tpl.php template.
 * All have internal .container classes. In the full width wrapper template the
 * .container classes set the width or max-width of the contained element - you
 * can style this but never add any width/margin/padding/borders etc to .container
 */
/*
 * Wraps #page
 */
/*
 * Wraps the leaderboard
 */
/*
 * Wraps the header
 */
#header-wrapper {
  /* Debug styles, is this working */
  background: rgba(255, 192, 203, 0.5); }

/*
 * Wraps the nav elements
 */
/*
 * Wraps breadcrumb
 */
/*
 * Wraps messages and help
 */
/*
 * Wraps the secondary content/preface region
 */
/*
 * Wraps the main content column
 */
/*
 * Wraps the tertiary content/postfix region
 */
/*
 * Footer wrapper
 */
#footer-wrapper {
  /* Debug styles, is this working? */
  background: rgba(255, 192, 203, 0.5); }

/* =============================================================================
 *   Branding
 * ========================================================================== */
/*
 * Wraps all the branding elements, logo, name and slogan
 */
/*
 * Logo
 */
#logo {
  padding: 0; }
#logo img {
  vertical-align: bottom; }

/*
 * Wrapper for the site name and slogan (hgroup)
 */
/*
 * Site name (h1)
 */
#site-name {
  margin: 0; }
#site-name a:link, #site-name a:visited {
  text-decoration: none; }
#site-name a:hover, #site-name a:focus {
  text-decoration: underline; }

/*
 * Site slogan (h2)
 */
#site-slogan {
  margin: 0; }

/* =============================================================================
 *   Page content header
 * ========================================================================== */
/*
 * Main content header wraps the page title, tabs and actions links
 */
/*
 * The main page title (h1)
 */
/* =============================================================================
 *   Misc Global Styles
 * ========================================================================== */
/*
 * Feed icons
 */
/*
 * Aggregator feed source
 */
#aggregator .feed-source .feed-icon {
  display: inline;
  float: none;
  margin-right: 10px; }

.feed-details dt,
.feed-details dd {
  display: inline;
  margin: 0; }

/*
 * Generic styles for the more link
 */
/*
 * Generic styles for links. See the ul.links declaration in node and comment stylesheets
 */
ul.links {
  margin: 0;
  padding: 0; }
ul.links.inline {
  display: block; }
ul.links li {
  display: inline;
  list-style: none;
  padding: 0 10px 0 0; }

/*
 * Search results are an ordered list so reset the margin
 */
.search-results {
  margin: 0; }

/* =============================================================================
 *   Regions
 * ========================================================================== */
/*
 * Standard region wrapper, don't add width to any regions, you will bork the layout - no margin, padding or borders etc
 */
/*
 * Regions have an inner div - perfect for adding margin, padding or borders
 */
/*
 * Regions can be nested, such as when using Panels
 */
/*
 * Header region, embedded in the #header
 */
/*
 * Generally used to output Drupals help block, if the help module is enabled
 */
/*
 * Secondary content
 */
/*
 * Sits above the main content header, like a content-top region
 */
/*
 * Sits below the main content, like a content-bottom region
 */
/*
 * Sidebars - targets both
 */
/*
 * First sidebar
 */
/*
 * Second sidebar
 */
/*
 * Tertiary content
 */
/*
 * Footer region
 */
/* =============================================================================
 *   Links
 * ========================================================================== */
a {
  text-decoration: none;
  color: #999999; }
a:hover, a:focus {
  text-decoration: underline; }

/* =============================================================================
 *   Primary, Secondary and Menu Bar region menus
 * ========================================================================== */
/*
 * Use one of the following id's for granular control:
 *  - #menu-bar (menu bar region)
 *  - #primary-menu (main menu)
 *  - #secondary-menu (secondary/user menu)
 * You can target all of these with .nav or div[id$="menu-bar"]
 */
nav.primary-menu-wrapper {
  margin: 0; }

ul.primary-menu li a:hover {
  background: #fff !important; }

#primary {
  background: none repeat scroll 0 0 #1B3459;
  border-radius: 5px 5px 5px 5px;
  padding: 10px 20px; }

#primary li {
  border-right: 1px dotted #CCCCCC;
  display: inline;
  margin-right: 20px;
  padding-bottom: 3px;
  padding-right: 20px;
  padding-top: 3px; }

#primary li a {
  color: #FFFFFF;
  font-size: 20px;
  letter-spacing: 1.3px;
  text-decoration: none;
  text-transform: uppercase; }

#primary li a:hover, #primary li a.active {
  color: #C2D4F4; }

.nav {
  clear: both;
  margin: 10px 0; }
.nav ul,
.nav ul.menu {
  margin: 0;
  padding: 0; }
.nav li,
.nav ul.menu li {
  display: inline;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0; }
.nav li a,
.nav ul.menu li a {
  display: block;
  white-space: nowrap;
  padding: 10px 10px;
  background: none repeat scroll 0 0 #231F20;
  color: #ccc;
  display: block;
  height: 25px; }
.nav li a:hover, .nav li a:focus,
.nav ul.menu li a:hover,
.nav ul.menu li a:focus {
  color: #696316; }
.nav .block {
  margin-bottom: 0; }

/* =============================================================================
 *   Superfish
 * ========================================================================== */
ul.sf-menu {
  margin-bottom: 0; }
ul.sf-menu a {
  border-left: 0;
  border-top: 0;
  padding: 0 10px;
  text-decoration: none;
  height: 2.5em;
  line-height: 2.5em; }
ul.sf-menu li:hover, ul.sf-menu li.sfHover {
  outline: 0; }
ul.sf-menu a:focus, ul.sf-menu a:hover, ul.sf-menu a:active {
  outline: 0; }

/*
 * Superfish blocks
 */
.block-superfish ul {
  margin: 0 !important;
  padding: 0 !important; }

.block-superfish li {
  margin: 0 !important;
  padding: 0 !important; }

/*
 * Vertical style
 */
.sf-vertical {
  width: 100%; }
.sf-vertical li {
  width: 100%; }
.sf-vertical li:hover ul,
.sf-vertical li.sfHover ul {
  left: 100%;
  top: 0;
  margin: 0;
  padding: 0; }
.sf-vertical li a {
  padding: 0 10px; }

/*
 * Navbar style
 */
.sf-navbar {
  padding-bottom: 0 !important; }

/*
 * Sensible padding for the default style
 */
.sf-menu.sf-style-default a {
  padding: 0 10px; }

/* =============================================================================
 *   Menus, usually blocks
 * ========================================================================== */
ul.menu {
  padding-left: 15px; }
ul.menu ul {
  padding-left: 15px; }
ul.menu li {
  margin: 0; }

/* If li.content exists it's a problem, so reset the padding */
.block .menu li.content {
  padding: 0; }

/* =============================================================================
 *   Primary menu (Main Menu)
 * ========================================================================== */
#primary-menu-bar {
  background: #231F20;
  margin: 0; }

#primary-menu-bar ul li a.active {
  color: #696316; }

/* =============================================================================
 *   Book navigation menu
 * ========================================================================== */
.book-navigation .page-up {
  /* Prevent text wrapping to a new line, assumes English "up" is used (two characters) */
  min-width: 2em;
  white-space: nowrap; }

.book-navigation .menu {
  margin-left: 0; }

/* =============================================================================
 *   Breadcrumbs
 * ========================================================================== */
#breadcrumb {
  margin: 10px 0;
  /* If the label is set to show in theme settings the label class is added */ }
#breadcrumb .breadcrumb-label {
  font-size: 1em;
  display: inline;
  padding-right: 10px; }
#breadcrumb .breadcrumb-label:after {
  content: ":"; }
#breadcrumb ol {
  margin: 0;
  padding: 0; }
#breadcrumb .with-breadcrumb-label ol {
  display: inline; }
#breadcrumb li {
  list-style: none;
  display: inline; }

/* =============================================================================
 *   Pagers
 * ========================================================================== */
ul.pager {
  clear: both;
  margin: 0;
  text-align: center; }

.item-list ul.pager li {
  margin: 0; }

ul.pager li {
  background-image: none;
  display: inline;
  list-style-type: none;
  padding: .5em; }
ul.pager li.pager-current {
  font-weight: 700; }

.block ul.pager li {
  margin: 0; }

/*
 * Theme the various states of pager links
 */
/* =============================================================================
 *   Skip Navigation
 * ========================================================================== */
#skip-link {
  left: 50%;
  margin-left: -6.5em;
  margin-top: 0;
  padding: 0 0.5em;
  position: absolute;
  width: 12em;
  z-index: 50; }
#skip-link a {
  background: #444;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: block;
  line-height: 2;
  padding: 0;
  text-align: center;
  text-decoration: none; }
#skip-link a:link, #skip-link a:visited {
  background: #444;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: block;
  line-height: 2;
  padding: 0;
  text-align: center;
  text-decoration: none; }
#skip-link a:hover, #skip-link a:focus, #skip-link a:active {
  outline: 0; }

/* =============================================================================
 *   Tabs (local tasks)
 * ========================================================================== */
#tasks {
  margin-bottom: 15px; }

ul.primary {
  border-bottom-color: #ccc;
  margin: 20px 0;
  padding: 0 0 0 5px; }
ul.primary li {
  display: block;
  float: left;
  margin: 0 1px -1px; }
ul.primary li a {
  background-color: #f5f5f5;
  border-color: #ccc;
  margin-right: 1px;
  padding: 0 10px;
  display: block;
  float: left;
  height: 1.5em;
  line-height: 1.5em; }
ul.primary li a:hover, ul.primary li a:focus {
  background-color: #eee;
  border-color: #ccc; }
ul.primary li.active a,
ul.primary li.active a:hover,
ul.primary li.active a:focus {
  background-color: #fff;
  border-bottom-color: #fff; }

ul.secondary {
  border-bottom: 1px solid #ccc;
  margin: 1em 0 0;
  padding: 0 .3em 1em; }
ul.secondary li {
  border-right: 0;
  list-style: none;
  padding: 0 10px 0 0; }
ul.secondary li a:hover, ul.secondary li a.active {
  border-bottom: none;
  text-decoration: underline; }

/* =============================================================================
 *   Action links
 * ========================================================================== */
ul.action-links {
  margin: 20px 0 0;
  list-style: none; }

/* =============================================================================
 *  Field Styling
 * ========================================================================== */
/*
 * Wrapper for any field
 */
/*
 * Above and inline classes are on the field wrapper
 */
/*
 * When labels are set to inline in field display settings the clearfix class is automatically added
 */
/*
 * Labels are h2 in Adaptivetheme. Use a strong selector to mitigate unwanted ineritance issues
 */
.field-label {
  font-size: 1em;
  font-weight: 700;
  font-family: inherit;
  line-height: inherit;
  margin-bottom: 0; }

/*
 * Field types (Core)
 */
/*
 * Image fields use the <figure> and <figcaption> elements from HTML5
 */
/*
 * Taxonomy
 */
.field-type-taxonomy-term-reference {
  /* The same bottom margin as p, blockquote, ul, ol and dl */
  margin-bottom: 1.5em; }
.field-type-taxonomy-term-reference.field-label-inline .field-items {
  margin: 0;
  padding: 0; }
.field-type-taxonomy-term-reference.field-label-inline .field-item {
  display: inline;
  list-style: none;
  padding: 0 10px 0 0; }

/*
 * Text
 */
/*
 * Long text
 */
/*
 * Text with summary
 */
/*
 * File
 */
/*
 * Number/Integer
 */
/*
 * Decimal
 */
/*
 * Number float
 */
/*
 * List
 */
/*
 * List boolean
 */
/*
 * List integer
 */
/*
 * List float
 */
/*
 * Field types (Contrib)
 */
/*
 * Named fields
 */
/*
 * Underscores in field name are replaced with dashes
 */
/*
 * Image Alignment Theme Settings - included here so you can easily override
 */
/*
 * Float none setting
 */
/*
 * Float left setting
 */
.ia-l .field-type-image figure,
.iat-l .field-type-image figure {
  margin: 5px 20px 15px 0; }

/*
 * Centered setting
 */
.ia-c .field-type-image figure,
.iat-c .field-type-image figure {
  margin: 5px auto 15px; }

/*
 * Float right setting
 */
.ia-r .field-type-image figure,
.iat-r .field-type-image figure {
  margin: 5px 0 15px 20px; }

/* =============================================================================
 *   Block Styling
 * ========================================================================== */
/*
 * Main wrapper for most blocks, block_system_main does not have it
 */
.block {
  margin-bottom: 20px; }

/*
 * Inner wrapper for most blocks, good for margin, padding and borders, block_system_main does not have it
 */
/*
 * The first block in the region
 */
/*
 * The last block in the region
 */
/*
 * Zebra striping for each block in the region
 */
/*
 * Zebra striping for each block in the region
 */
/*
 * Block title
 */
.block-title {
  margin: 0; }

/*
 * Block content wrapper
 */
/*
 * Match item list and block menu margin and padding
 */
.block-content ul,
.block-content ol {
  padding: 0 0 0 15px; }

.block-content li {
  margin: 0;
  padding: 0; }

/*
 * Block for the latest news items in the first category
 */
/*
 * Block for the latest news items in the first feed
 */
/*
 * First block created with "Add block" link
 */
/*
 * "Recent blog posts" block
 */
/*
 * "Book navigation" block for the current book's table of contents
 */
/*
 * "Recent comments" block
 */
/*
 * "Active forum topics" block
 */
/*
 * "New forum topics" block
 */
/*
 * Language switcher block
 */
/*
 * Custom menu block
 */
/*
 * "Recent content" block
 */
/*
 * "Syndicate" block for primary RSS feed; see also page.css's .feed-icon
 */
/*
 * "Most recent poll" block
 */
/*
 * "Author information" block for the profile of the page's author
 */
/*
 * "Search form" block
 */
/*
 * "Shortcuts" block
 */
/*
 * "Popular content" block
 */
/*
 * "Main menu" block
 */
/*
 * "Management" block for Drupal management menu
 */
/*
 * "Navigation" block for Drupal navigation menu
 */
/*
 * "User menu" block for Drupal user menu
 */
/*
 * "System help" block
 */
/*
 * "Main page content" block
 */
/*
 * "Powered by Drupal" block
 */
/*
 * "User login form" block
 */
/*
 * "Who's new" block for a list of the newest users
 */
/*
 * "Who's online" block for a list of the online users
 */
/* =============================================================================
 *   Node Styling
 * ========================================================================== */
.node {
  margin-bottom: 20px; }
.node .node-title {
  margin: 0; }

/*
 * All nodes are given a node-FOO class that describes the type of content that
 * it is. If you create a new content type called "my-custom-type", it will
 * receive a "node-my-custom-type" class.
 */
/* =============================================================================
 *   Comment Styling - Comments, comment wrapper, comment form
 * ========================================================================== */
/*
 * Wrapper for the list of comments and its titles
 */
#comments {
  margin: 1.5em 0; }
#comments h2.comment-title {
  margin: 0; }
#comments h2.comment-form {
  margin: 0; }

/*
 * Wrapper for a single comment
 */
.comment {
  margin-bottom: 20px; }

/*
 * Comment title
 */
.comment-title {
  margin: 0; }

/*
 * Comment states
 */
/*
 * Preview of the comment before submitting new or updated comment
 */
/*
 * "New" marker for comments that are new for the current user
 */
.new {
  color: #c00; }

/*
 * Nested comments are indented
 */
.indented {
  margin-left: 40px; }

/* =============================================================================
 *   Forms
 * ========================================================================== */
/*
 * Wrapper for a form element (or group of form elements) and its label
 */
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00; }

.form-item label {
  font-weight: 700; }

.form-item .description {
  font-size: 0.85em; }

/*
 * Highlight marks and form required mark
 */
.marker,
.form-required {
  color: #c00; }

/*
 * The submit button
 */
.container-inline div,
.container-inline label {
  display: inline; }

/*
 * Define consistent borders
 */
fieldset {
  border: 1px solid #ccc; }

/*
 * Tips for Drupal's input formats
 */
/*
 * Buttons used by contrib modules like Media
 */
/*a.button {
  @include appearance(button);
}*/
/*
 * Password confirmation
 */
.password-parent,
.confirm-parent {
  margin: 0; }

/* =============================================================================
 *   Tables
 * ========================================================================== */
table {
  margin: 10px 0;
  padding: 0;
  width: 100%; }
table.sticky-header {
  z-index: 10; }

table,
thead,
tbody,
tr,
th,
td {
  border-color: #ccc; }

table,
td,
th {
  vertical-align: middle; }

caption,
th,
td {
  text-align: left; }

thead tr {
  font-weight: 700;
  background-color: #e5e5e5; }

td,
th {
  border-bottom: 0;
  margin: 0;
  padding: 5px 7px; }

tbody tr {
  border-top: 1px solid #ccc; }

tr {
  /* Table row striping */ }
tr.odd {
  background: #fff; }
tr.info, tr.even, tr:nth-child(2n+2) {
  border-bottom: 0;
  background-color: #f5f5f5; }
tr.odd td.active {
  background-color: #eee; }
tr.even td.active {
  background-color: #ebebeb; }

/*
 * Forum tables
 * Core sets white-space to nowrap, which makes no sense
 */
#forum td .created,
#forum td .posts,
#forum td .topics,
#forum td .last-reply,
#forum td .replies,
#forum td .pager {
  white-space: normal; }

/* =============================================================================
 *   Messages
 * ========================================================================== */
div.messages {
  margin-bottom: 10px;
  margin-top: 10px; }
div.messages ul {
  margin-top: 0;
  margin-bottom: 0; }

/*
 * Unpublished nodes
 */
.node-unpublished p.unpublished,
.comment-unpublished p.unpublished {
  color: pink;
  color: rgba(239, 170, 170, 0.4);
  font-family: Impact, Haettenschweiler, "Franklin Gothic Bold", Charcoal, "Helvetica Inserat", "Bitstream Vera Sans Bold", "Arial Black", sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  height: 0;
  margin: 0;
  padding: 0;
  overflow: visible;
  text-align: center;
  text-transform: uppercase;
  word-wrap: break-word; }
.ie6-7 .node-unpublished p.unpublished > *, .ie6-7
.comment-unpublished p.unpublished > * {
  position: relative; }

/* =============================================================================
 *    Maintenance pages
 * ========================================================================== */
.maintenance-page .container {
  padding: 40px 0; }

.db-offline .container {
  margin: 0 auto;
  padding: 40px 0;
  width: 100%;
  max-width: 960px; }

.db-offline div.messages {
  margin: 20px 0 0; }

.db-offline #content {
  padding: 20px 0; }

/* =============================================================================
 *   Misc overrides for contrib modules
 * ========================================================================== */
/*
 * Admin menu overrides
 */
#admin-menu {
  margin: 0;
  padding: 0; }

/*
 * Devel Module
 */
.dev-query {
  background: #eee;
  padding: 30px; }

/*
 * Styleguide module tweaks
 */
#styleguide-header {
  padding: 0 10px; }
#styleguide-header .item-list {
  font-family: inherit;
  margin: 0 20px 20px 0;
  min-height: 260px;
  width: auto; }

/* Homepage */
.hp-image {
  width: 350px;
  height: 233px;
  float: right;
  padding: 10px;
  -webkit-box-shadow: #aaaaaa 0px 0px 5px;
  -webkit-transform: rotate(2deg); }

/*
 * Stylist Profile
 */
.view-stylists li {
  border-bottom: 1px dotted #CCCCCC;
  clear: both;
  list-style: none outside none;
  overflow: hidden; }

.view-stylists li.views-row-last {
  border-bottom: none; }

.view-stylists .views-field-title {
  float: right;
  font-family: inherit;
  font-size: 30px;
  font-weight: bold;
  padding: 20px;
  color: #000; }

.view-stylists .views-field-field-image {
  float: left;
  margin: 0 20px 0 0; }

.view-stylists .views-field-field-image img {
  padding: 7px;
  border: 1px solid #ccc;
  box-shadow: 4px 4px 10px #ccc; }

.view-stylists .views-field-field-role {
  font-size: 20px;
  color: #000; }

.view-stylists .item-list ul li {
  margin: 0;
  padding-bottom: 30px;
  margin-bottom: 30px; }

/*
 * Product Displays
 */
.view-id-product_list .item-list {
  margin-bottom: 40px; }

/*.view-product-list h3 {
    background: none repeat scroll 0 0 #231F20;
    border-radius: 5px 5px 5px 5px;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 6px 15px;
}*/
.view-product-list h3 {
  border-bottom: 1px dotted #ccc; }

.view-product-list ul li {
  display: inline-block;
  list-style: none outside none;
  width: 20%; }

/*
 * media-gallery-collection
 */
/*.media-gallery-collection {margin: 0 25px;}*/
/*
 * Webform DS Columns
 */
.group-right {
  float: left; }

.node-webform .group-right {
  width: 66%; }
.node-webform .group-right .field-name-gmap {
  margin-left: 20px; }
.node-webform .group-right .field-name-gmap iframe {
  border: 1px solid #ccc;
  padding: 5px; }

.node-webform .group-left {
  width: 33%; }

#views_slideshow_cycle_main_slideshow-block {
  height: 550px; }

.front #block-system-main {
  margin-bottom: 0; }
.front #block-system-main .node {
  margin-bottom: 0; }

#main-content {
  padding: 0px 40px 0 40px; }

/*
.sidebar-first #main-content{
	padding-top: 70px;
}
*/
/* Header */
#header-bg {
  position: absolute; }

#header {
  z-index: 10;
  position: relative;
  height: auto; }

.menu li {
  display: inline; }
.menu li a {
  font-size: 150%;
  padding: 10px; }
.menu li a:hover, .menu li a.active {
  color: #000;
  text-decoration: none; }
.menu li li.last a {
  padding-right: 0; }

#branding, .region-header {
  text-align: center; }

#block-system-main-menu .menu {
  text-align: center; }

.views_slideshow_cycle_main {
  width: 100%;
  float: left; }

.views_slideshow_cycle_main .views-slideshow-cycle-main-frame {
  width: 100% !important;
  height: auto; }

.views_slideshow_cycle_main .views-slideshow-cycle-main-frame-row {
  width: 100% !important;
  height: auto; }

.views_slideshow_cycle_main .field-content {
  max-width: 100%;
  width: 100%; }

.views_slideshow_cycle_main .field-content img {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

.node-webform .group-right .field-name-gmap .field-item {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden; }

.node-webform .group-right .field-name-gmap .field-item iframe,
.node-webform .group-right .field-name-gmap .field-item object,
.node-webform .group-right .field-name-gmap .field-item embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.view-services .item-list ul li {
  list-style-type: none;
  padding: 0;
  margin: 0 0 25px 0; }

.view-services h2 {
  font-size: 16px;
  margin-bottom: 5px; }

.views-field-field-service-collection .item-list ul li {
  padding: 3px;
  border-bottom: 1px solid #eee;
  margin-bottom: 0; }

.views-field-field-service-collection li.even {
  background: #f8f8f8; }

.views-field-field-service-collection .ds-2col-fluid .group-left {
  width: 75%; }

.views-field-field-service-collection .ds-2col-fluid .group-right {
  width: 25%; }

/*# sourceMappingURL=global.styles.css.map */